
import { defineComponent, ref } from 'vue'
import ClassicEditor from '@/global-components/ckeditor/classic-editor'
import UploadAdapterPlugin from '@/global-components/ckeditor/upload-adapter-plugin'

export default defineComponent({
  setup () {
    const date = ref('')
    const classicEditor = ClassicEditor
    const simpleEditorConfig = {
      removePlugins: [
        "Markdown",
        "MediaEmbedToolbar",
        "ImageToolbar"
      ],
      toolbar: {
        items: ['bold', 'italic', 'underline', 'link']
      }
    }
    const editorConfig = {
      extraPlugins: [
        UploadAdapterPlugin
      ],
      removePlugins: [
        "Markdown",
        "MediaEmbedToolbar",
      ],
      toolbar: {
        items: [
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          // 'strikethrough',
          'code',
          // 'subscript',
          // 'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      },
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:side",
          "linkImage",
        ],
      },
    }
    const simpleEditorData = ref('<p>Content of the editor.</p>')
    const standardEditorData = ref('<p>Content of the editor.</p>')
    const inlineEditorData = ref('<p>Content of the editor.</p>')
    const balloonEditorData = ref('<p>Content of the editor.</p>')
    const documentEditorData = ref('<p>Content of the editor.</p>')
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        '.document-editor__toolbar'
      )
      if (toolbarContainer) toolbarContainer.appendChild(editor.ui.view.toolbar.element)
      const win: any = window
      win.editor = editor
    }

    return {
      date,
      classicEditor,
      simpleEditorConfig,
      editorConfig,
      simpleEditorData,
      standardEditorData,
      inlineEditorData,
      balloonEditorData,
      documentEditorData,
      initDocumentEditor
    }
  }
})
